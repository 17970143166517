import { trackAction } from "utils/mixpanel"
import ContactUsLink from "components/shared/ContactUsLink"
import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"

import styles from "./allEmptyTable.module.scss"

const AllEmptyTable = () => (
  <article className={styles.allEmptyTable}>
    <div className={styles.allEmptyTable__icon}>
      <Icon name="customer" height="50" width="50" />
    </div>

    <h6>No applicants yet</h6>
    <p>
      Contact us if you havn&apos;t already or advertise an existing job to get
      more views (and hopefully, more applicants)
    </p>

    <div className={styles.allEmptyTable__actions}>
      <ContactUsLink>
        <PrimaryButton
          onClick={() => {
            trackAction("Call to Action Clicked", {
              location: "Empty Applicants Table",
              click_text: "Contact Us",
              click_url: "support@repvue.com",
              modal_name: null,
            })
          }}
        >
          <span>Contact us</span>
          <Icon name="external-link" height="20" width="20" />
        </PrimaryButton>
      </ContactUsLink>
    </div>
  </article>
)

export default AllEmptyTable
