import { trackAction } from "utils/mixpanel"
import ContactUsLink from "components/shared/ContactUsLink"
import Icon from "components/Icon"
import PrimaryButton from "components/shared/Buttons/Primary"

import styles from "./Empty.module.scss"

const Empty = ({ noApplicants, section }) => {
  const TAB_CONTENT = {
    New: {
      headline: "No new applicants yet",
      body: "Candidates that have recently applied will appear here. Contact us to boost your visibility to potential applicants via job ads on your talent competitor’s profiles!",
      buttons: true,
    },
    Engagement: {
      headline: "Your engagement funnel is empty",
      body: "Candidates that have entered your recruiting funnel will be displayed here. Engage with new applicants to move them into this phase.",
      buttons: false,
    },
    "Hire successful": {
      headline: "No hires yet",
      body: "Candidates that have been successfully hired will be showcased here. Contact us to find your next hire by securing job ads on your talent competitor’s profiles!",
      buttons: true,
    },
    Declined: {
      headline: "No declined candidates",
      body: "Candidates that have declined will be archived here",
      buttons: false,
    },
  }

  return (
    <article className={styles.allEmptyTable}>
      <div className={styles.allEmptyTable__icon}>
        <Icon name="customer" height="50" width="50" />
      </div>

      <h6>
        {!noApplicants
          ? "No applicants matched your search."
          : TAB_CONTENT[section].headline}
      </h6>
      <p>{noApplicants && TAB_CONTENT[section].body}</p>

      {noApplicants && TAB_CONTENT[section].buttons && (
        <div className={styles.allEmptyTable__actions}>
          <ContactUsLink>
            <PrimaryButton
              onClick={() => {
                trackAction("Call to Action Clicked", {
                  location: "Empty Applicants Table",
                  click_text: "Contact Us",
                  click_url: "support@repvue.com",
                  modal_name: null,
                })
              }}
            >
              <Icon name="external-link" height="20" width="20" />
              <span>Contact Us</span>
            </PrimaryButton>
          </ContactUsLink>
        </div>
      )}
    </article>
  )
}

export default Empty
