import { useState, useEffect } from "react"
import Card from "components/shared/Card"
import { getFilterOptions } from "api/candidate/profiles"
import { trackAction } from "utils/mixpanel"

import AccordionFilter from "components/shared/Filters/AccordionFilter"
import SwitchFilter from "components/shared/Filters/Switch"
import SearchFilter from "components/shared/Filters/Search"
import useFilters from "components/candidates/Hooks/useFilters"
import styles from "./filters.candidate.module.scss"

const CandidateFilters = ({ handleFilter, filters, setFilters }) => {
  const [headquarters, setHeadquarters] = useState([])
  const [formOptions, setFormOptions] = useState({})
  const [companies, setCompanies] = useState([])

  const { FILTERS } = useFilters(
    filters,
    setFilters,
    headquarters,
    setHeadquarters,
    formOptions,
    setFormOptions,
    handleFilter,
    companies,
    setCompanies
  )

  const setDefaults = () => {
    getFilterOptions()
      .then(response => {
        setFormOptions(response)
        setCompanies(response.companies)
      })
      .catch(error => console.error(error))
  }

  const searchCandidates = search => {
    setFilters({
      ...filters,
      search,
    })
    trackAction("Candidate Search Applied", {search_context: search})
  }

  useEffect(() => {
    setDefaults()
  }, [])

  return (
    <Card className={styles.filters__card}>
      <article>
        <SearchFilter
          handleSearch={searchCandidates}
          placeholder="Search Candidates"
        />

        {FILTERS.map(filter => {
          return (
            <AccordionFilter
              type={filter.type}
              title={filter.title}
              eventKey={FILTERS.indexOf(filter)}
              body={filter.body}
              key={FILTERS.indexOf(filter)}
              watchedValues={filter.watchedValues}
              removeFilter={filter.removeFilter}
              className={styles.filters__accordion}
              headerClassName={styles.filters__accordion__header}
              icon="down-arrow"
              showBadge
            />
          )
        })}

        <SwitchFilter
          setFilters={setFilters}
          filters={filters}
          label="Show Only Actively Looking"
          name={"actively_looking"}
        />

        {/* <SwitchFilter
          setFilters={setFilters}
          filters={filters}
          label="Hide Engaged Candidates"
          name={"engaged"}
        /> */}
      </article>
    </Card>
  )
}

export default CandidateFilters
