import { SALES_CYCLE_LABELS } from "constants/candidates"

export const getSoldToLabels = experience => {
  const names = experience?.selling_personas?.map(item => item.name)
  const result = names?.join(", ")
  return result
}

export const generateCandidateParams = (
  filters = {},
  page = 1,
  per_page = 25
) => {
  const {
    actively_looking: activelyLooking,
    avgDealSize,
    salesCycleLength,
    selectedRoles,
    headquarters,
    selectedIndustries,
    search,
    selectedCompanies,
    yearsExperience,
    yearsClosingExperience,
    selectedPersonas,
    onlyCurrentRoles: only_current_roles,
  } = filters

  const getAverageDealSizeParams = () => {
    if (!avgDealSize) return null

    const { min, max } = avgDealSize
    if (min === 0 && max === 1000000) return null

    return {
      "candidate_sales_experiences_candidate_profiles.average_deal_size": [
        min,
        max,
      ],
      "candidate_sales_experiences_candidate_profiles.average_deal_size.rel":
        "between",
    }
  }

  const getYearsExperienceParams = () => {
    if (!yearsExperience) return null

    const { min, max } = yearsExperience
    if (min === 0 && max === 11) return null

    return {
      "job_preferences_candidate_profiles.years_selling_experience": [min, max],
      "job_preferences_candidate_profiles.years_selling_experience.rel":
        "between",
    }
  }

  const getYearsClosingExperienceParams = () => {
    if (!yearsClosingExperience) return null

    const { min, max } = yearsClosingExperience
    if (min === 0 && max === 11) return null

    return {
      "job_preferences_candidate_profiles.years_closing_experience": [min, max],
      "job_preferences_candidate_profiles.years_closing_experience.rel":
        "between",
    }
  }

  const getSearchStatus = () => {
    if (activelyLooking)
      return {
        "job_preferences_candidate_profiles.search_status": "Actively Looking",
      }
  }

  const getsalesCycleLengthParams = () => {
    if (!salesCycleLength) return null

    const { min, max } = salesCycleLength
    if (min === 0 && max === 16) return null

    return {
      "candidate_sales_experiences_candidate_profiles.sales_cycle_length_days":
        [SALES_CYCLE_LABELS[min], SALES_CYCLE_LABELS[max]],
      "candidate_sales_experiences_candidate_profiles.sales_cycle_length_days.rel":
        "between",
    }
  }

  return {
    page,
    per_page,
    ...getSearchStatus(),
    ...getAverageDealSizeParams(),
    ...getYearsExperienceParams(),
    ...getYearsClosingExperienceParams(),
    ...getsalesCycleLengthParams(),
    "roles.name": selectedRoles,
    "metro_locations_job_preferences.id": headquarters?.map(item => item.id),
    "industries_companies.name": selectedIndustries,
    "selling_personas.name": selectedPersonas,
    "companies_candidate_sales_experiences.name": selectedCompanies?.map(
      company => company?.name
    ),
    search,
    only_current_roles,
  }
}
