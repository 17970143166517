import { useMemo } from "react"
import { getMetroLocationsSansCities } from "api/metro_locations"
import { YEARS_LABELS, SALES_CYCLE_LABELS } from "constants/candidates"
import { searchCompaniesProfile } from "api/companies"
import CategorySelect from "components/shared/Filters/CategorySelect"
import SliderSelect from "components/shared/Filters/SliderSelect"
import HeadquartersSearch from "components/shared/Filters/HeadquartersSearch"
import CompanySearch from "components/shared/Filters/CompanySearch"
import SwitchFilter from "components/shared/Filters/Switch"

const useFilters = (
  filters,
  setFilters,
  headquarters,
  setHeadquarters,
  formOptions,
  setFormOptions,
  handleFilter,
  companies,
  setCompanies
) => {
  const handleHeadquartersSelect = headquarters => {
    if (filters.headquarters && filters.headquarters.includes(headquarters)) {
      const newHeadquarters = [...filters.headquarters].filter(
        selectedCompany => selectedCompany !== headquarters
      )
      setFilters({ ...filters, headquarters: newHeadquarters })
    } else {
      let newHeadquarters = [...filters.headquarters]
      newHeadquarters.push(headquarters)
      setFilters({ ...filters, headquarters: newHeadquarters })
    }
  }

  const conjureWatchedValues = (state, min, max, type, labels) => {
    const valueArray = []

    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    })

    Object.keys(state).forEach(valueRange => {
      if (valueRange === "min" && state[`${valueRange}`] > min) {
        valueArray.push(
          type === "currency"
            ? `Min: ${currencyFormatter.format(state.min)}`
            : type === "percentage"
            ? `Min: ${state.min}%`
            : `Min: ${labels ? labels[state.min] : state.min}`
        )
      } else if (valueRange === "max" && state[`${valueRange}`] < max) {
        valueArray.push(
          type === "currency"
            ? `Max: ${currencyFormatter.format(state.max)}`
            : type === "percentage"
            ? `Max: ${state.max}%`
            : `Max: ${labels ? labels[state.max] : state.max}`
        )
      }
    })
    return valueArray.length ? valueArray : []
  }

  const getCompanies = search => {
    searchCompaniesProfile(search)
      .then(response => {
        setCompanies(response.companies)
      })
      .catch(error => console.error(error))
  }

  const searchHeadquartersFilter = search => {
    getMetroLocationsSansCities(search)
      .then(response => setHeadquarters(response.rows))
      .catch(error => console.error(error))
  }

  const removeSliderValue = (key, value, min, max) => {
    if (value.slice(0, 3) === "Min") {
      setFilters({
        ...filters,
        [`${key}`]: { min: min, max: filters[`${key}`].max },
      })
    } else {
      setFilters({
        ...filters,
        [`${key}`]: { min: filters[`${key}`].min, max: max },
      })
    }
  }

  const filterRequest = () => {
    handleFilter(0, "")
  }

  const handleCompanySelect = company => {
    if (
      filters.selectedCompanies.includes &&
      filters.selectedCompanies.includes(company)
    ) {
      const newSelectedCompanies = [...filters.selectedCompanies].filter(
        selectedCompany => selectedCompany !== company
      )
      setFilters({ ...filters, selectedCompanies: newSelectedCompanies })
    } else {
      let newSelectedCompanies = [...filters.selectedCompanies]
      newSelectedCompanies.push(company)
      setFilters({ ...filters, selectedCompanies: newSelectedCompanies })
    }
  }

  const FILTERS = useMemo(
    () => [
      {
        title: "Role Experience",
        watchedValues: filters.selectedRoles,
        removeFilter: role => {
          const newRoles = [...filters.selectedRoles].filter(
            selectedRole => selectedRole !== role
          )
          setFilters({ ...filters, selectedRoles: newRoles })
        },
        get body() {
          return (
            <>
              <SwitchFilter
                setFilters={setFilters}
                filters={filters}
                label="Show Only Current Role"
                name="onlyCurrentRoles"
              />

              <CategorySelect
                categories={formOptions?.roles?.map(role => role?.name) || []}
                watchedCategories={filters.selectedRoles}
                addFilter={role =>
                  setFilters({
                    ...filters,
                    selectedRoles: [...filters.selectedRoles, role],
                  })
                }
                removeFilter={this.removeFilter}
              />
            </>
          )
        },
      },

      {
        title: "Years of Experience",
        watchedValues: conjureWatchedValues(
          filters.yearsExperience,
          0,
          11,
          "text",
          YEARS_LABELS
        ),
        removeFilter: value =>
          removeSliderValue("yearsExperience", value, 0, 11),
        body: (
          <SliderSelect
            min={0}
            max={11}
            filters={filters}
            filter="yearsExperience"
            setFilter={setFilters}
            step={1}
            labelMin="<1Y"
            labelMax="10Y+"
            filterRequest={filterRequest}
            options={YEARS_LABELS}
            inputTye="text"
          />
        ),
      },

      {
        title: "Years Closing Experience",
        watchedValues: conjureWatchedValues(
          filters.yearsClosingExperience,
          0,
          11,
          "text",
          YEARS_LABELS
        ),
        removeFilter: value =>
          removeSliderValue("yearsClosingExperience", value, 0, 11),
        body: (
          <SliderSelect
            min={0}
            max={11}
            filters={filters}
            filter="yearsClosingExperience"
            setFilter={setFilters}
            step={1}
            labelMin="<1Y"
            labelMax="10Y+"
            filterRequest={filterRequest}
            options={YEARS_LABELS}
            inputTye="text"
          />
        ),
      },

      {
        title: "Locations",
        body: (
          <HeadquartersSearch
            headquartersArray={headquarters}
            handleSearch={searchHeadquartersFilter}
            selectedHeadquarters={filters.headquarters}
            handleSelect={handleHeadquartersSelect}
          />
        ),
        watchedValues:
          filters.headquarters?.length > 0
            ? filters.headquarters.map(location => location.name)
            : [],
        removeFilter: headquarters => {
          const newHeadquarters = [...filters.headquarters].filter(location =>
            location.name
              ? location.name !== headquarters
              : location !== headquarters
          )
          setFilters({ ...filters, headquarters: newHeadquarters })
        },
      },

      {
        title: "Industries",
        watchedValues: filters.selectedIndustries,
        removeFilter: industry => {
          const newIndustries = [...filters.selectedIndustries].filter(
            selectedIndustry => selectedIndustry !== industry
          )
          setFilters({ ...filters, selectedIndustries: newIndustries })
        },
        get body() {
          return (
            <CategorySelect
              categories={
                formOptions?.industries?.map(industry => industry?.name) || []
              }
              watchedCategories={filters.selectedIndustries}
              addFilter={industry =>
                setFilters({
                  ...filters,
                  selectedIndustries: [...filters.selectedIndustries, industry],
                })
              }
              removeFilter={this.removeFilter}
            />
          )
        },
      },
      {
        title: "Buyer Persona",
        watchedValues: filters.selectedPersonas,
        removeFilter: persona => {
          const newPersonas = [...filters.selectedPersonas].filter(
            selectedPersonas => selectedPersonas !== persona
          )
          setFilters({ ...filters, selectedPersonas: newPersonas })
        },
        get body() {
          return (
            <CategorySelect
              categories={formOptions?.selling_personas || []}
              watchedCategories={filters.selectedPersonas}
              addFilter={persona =>
                setFilters({
                  ...filters,
                  selectedPersonas: [...filters.selectedPersonas, persona],
                })
              }
              removeFilter={this.removeFilter}
            />
          )
        },
      },

      {
        title: "Sales Cycle Length",
        watchedValues: conjureWatchedValues(
          filters.salesCycleLength,
          0,
          16,
          "text",
          SALES_CYCLE_LABELS
        ),
        removeFilter: value =>
          removeSliderValue("salesCycleLength", value, 0, 16),
        body: (
          <SliderSelect
            min={0}
            max={16}
            filters={filters}
            filter="salesCycleLength"
            setFilter={setFilters}
            step={1}
            labelMin="1W"
            labelMax="12M"
            filterRequest={filterRequest}
            options={SALES_CYCLE_LABELS}
            inputTye="text"
          />
        ),
      },

      {
        title: "Avg. Deal Size",
        watchedValues: conjureWatchedValues(
          filters.avgDealSize,
          0,
          1000000,
          "currency"
        ),
        removeFilter: value =>
          removeSliderValue("avgDealSize", value, 0, 1000000),
        body: (
          <SliderSelect
            min={0}
            max={1000000}
            filters={filters}
            filter="avgDealSize"
            setFilter={setFilters}
            step={2500}
            labelMin="$0"
            labelMax="$1M"
            filterRequest={filterRequest}
            type="currency"
          />
        ),
      },

      {
        title: "Target Companies",
        body: (
          <CompanySearch
            companies={companies}
            handleSearch={getCompanies}
            selectedCompanies={filters.selectedCompanies}
            handleSelect={handleCompanySelect}
            placeholder="Search Target Companies"
          />
        ),
        watchedValues:
          filters.selectedCompanies?.length > 0
            ? filters.selectedCompanies.map(company =>
                company.name ? company.name : company
              )
            : [],
        removeFilter: company => {
          const newSelectedCompanies = [...filters.selectedCompanies].filter(
            selectedCompany =>
              selectedCompany.name
                ? selectedCompany.name !== company
                : selectedCompany !== company
          )
          setFilters({ ...filters, selectedCompanies: newSelectedCompanies })
        },
      },
    ],
    [
      filters,
      setFilters,
      headquarters,
      setHeadquarters,
      formOptions,
      setFormOptions,
      companies,
      setCompanies,
    ]
  )

  return {
    FILTERS,
  }
}

export default useFilters
