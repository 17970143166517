import { getSoldToLabels } from "utils/candidateProfile"
import { getCurrencyFormat } from "utils/string"
import Tag from "./Tag"
import styles from "./tags.cardExperience.module.scss"

const TagsCardExperience = ({ experience }) => {
  return (
    <div className={styles.tagsCardExperience}>
      {experience?.selling_personas?.legth > 0 && (
        <Tag label="Sold to" value={getSoldToLabels(experience)} />
      )}

      {experience?.sales_cycle_length && (
        <Tag
          label="Sales Cycle Length"
          value={experience?.sales_cycle_length}
        />
      )}

      <Tag
        label="Avg. Deal Size"
        value={getCurrencyFormat(experience?.average_deal_size)}
      />
    </div>
  )
}

export default TagsCardExperience
