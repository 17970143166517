import classNames from "classnames"
import { trackAction } from "utils/mixpanel"
import ContactUsLink from "components/shared/ContactUsLink"
import Card from "components/shared/Card"
import ProgressBar from "react-bootstrap/ProgressBar"
import SecondaryLightButton from "components/shared/Buttons/SecondaryLight"
import { Link } from "react-router-dom"

import styles from "./Jobs.module.scss"

const JobsWidget = ({ activeJobsCount = 0 }) => {
  const atJobLimit = activeJobsCount >= 2

  return (
    <div className={styles.jobs}>
      <Card className={styles.jobs__card}>
        <div className={styles.jobs__title}>Post a Job</div>
        <div className={styles.jobs__value}>{`${
          activeJobsCount > 2 ? 2 : activeJobsCount
        } / 2`}</div>

        <div className={styles.jobs__quarterContainer}>
          <h6 className={styles.jobs__titleDescription}>Free jobs available</h6>
        </div>

        <ProgressBar className={styles.jobs__progressBar}>
          <ProgressBar
            className={styles.jobs__solid}
            now={atJobLimit ? 100 : activeJobsCount * 50}
            key={1}
          />
          <ProgressBar
            className={styles.jobs__striped}
            striped
            now={atJobLimit ? 0 : activeJobsCount === 0 ? 100 : 50}
            key={2}
            animated
          />
        </ProgressBar>
        <div className={styles.jobs__upgrade}>
          <p>
            By increasing your job postings you’ll reach more top talent.
            <ContactUsLink>Upgrade Now</ContactUsLink>
          </p>
        </div>

        <div
          className={classNames(styles.jobs__notification, {
            [styles.jobs__notification__atLimit]: atJobLimit,
          })}
        >
          <p>
            {atJobLimit
              ? "You're using all of your allocated jobs"
              : "You aren’t using all of your allocated jobs"}
          </p>
        </div>
        <SecondaryLightButton
          className={styles.jobs__action}
          disabled={atJobLimit}
          onClick={() =>
            trackAction("Call to Action Clicked", {
              location: "Dashboard",
              click_text: "Post a Job",
              click_url: `${process.env.REACT_APP_USER_URL}/jobs/new`,
              modal_name: null,
            })
          }
        >
          <Link to="/jobs/new">Post A Job</Link>
        </SecondaryLightButton>
      </Card>
    </div>
  )
}

export default JobsWidget
