import Icon from "components/Icon"
import styles from "./ContactUsLink.module.scss"

const ContactUsLink = ({ children, hasIcon }) => {
  return (
    <a
      href={`${process.env.REACT_APP_USER_URL}/contact`}
      target="_blank"
      rel="noreferrer"
      className={styles.contactUsLink}
    >
      {children || "Contact Us"}
      {hasIcon && <Icon name="external-link" height="18" width="18" />}
    </a>
  )
}

export default ContactUsLink
